import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function Tile(props) {
    return (
        <div className="bg-white mb-5">
            {
                props.image &&
                <img src={'https://via.placeholder.com/500x300'} className="rounded-md" />
                /* <StaticImage className="rounded-t-lg" src={props.image} alt={props.name} /> */
            }

            <div className="p-5">
                <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2 font-flow">{props.name}</h5>
                {
                    props.body &&
                    <p>{props.body}</p>
                }
            </div>
        </div>
    )
}

export default Tile