import React from 'react'
import loadable from '@loadable/component'

import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import { container, heading } from './layout.module.css'
// const CookieBanner = loadable(() => import('../components/gdpr/CookieBanner'))

function Layout({ pageTitle, children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <title>
        {pageTitle} | {data.site.siteMetadata.title}
      </title>
      <Header />
      <main className={`container ${container}`}>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
      {/* <CookieBanner /> */}
      <footer></footer>
    </div>
  )
}

export default Layout
