import * as React from 'react'
import Navigation from '../navigation'

function Header() {
    return (
        <header className="mt-3 sticky top-0 bg-white z-10">
            <Navigation />
        </header>
    )
}

export default Header