import * as React from 'react'
import loadable from '@loadable/component'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Tile from '../components/tiles/tile'
const ContactForm = loadable(() => import('../components/forms/ContactForm'))

const IndexPage = () => {
  return (
    <Layout>
      <Hero />

      <div className="mb-24 container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-12 ">
        {Array.from([
          {
            step: 1,
            title: 'Omschrijven',
            body: 'Omschrijf jouw wensen',
            callToActionText: 'Neem contact op',
          },
          {
            step: 2,
            title: 'Verbinden',
            body: 'Wij verbinden je met de juiste partij voor jouw',
            callToActionText: 'Onze partners',
          },
          {
            step: 3,
            title: 'Uitvoeren',
            body: 'Wij stellen de scherpste offerte op. Jij leeft zo snel mogelijk groener',
            callToActionText: 'Onze klanten',
          },
        ]).map((item, id) => {
          return (
            <div
              className="rounded-lg flex flex-col items-start p-6 sm:p-12 relative"
              key={id}
            >
              <h3 className="text-1xl sm:text-2xl lg:text-3xl text-center font-bold mb-4 flex flex-row items-center">
                <span className="bg-green-500 text-white rounded-full h-6 w-6 flex items-center justify-center mr-2 text-sm">
                  {item.step}
                </span>{' '}
                {item.title}
              </h3>
              <p className="mb-4">{item.body}</p>
              <button className="mt-auto hover:text-white hover:bg-transparent hover:border-purple-700 border bg-purple-600 transition duration-150 ease-in-out focus:outline-none mr-6  rounded text-white hover:text-purple-700 px-4 sm:px-8 py-1 sm:py-3 text-sm mb-2">
                {item.callToActionText}
              </button>
            </div>
          )
        })}
      </div>

      <div className="mb-24">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl text-center font-bold">
          Velen gingen voor
        </h2>
        <p className="text-center">
          Samen hebben we al meer dan <b className="text-green-500">100</b>{' '}
          Nederlandse huishoudens van het gas gehaald.
        </p>
      </div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 mb-16">
        <div className="w-full">
          <h3 className="text-1xl sm:text-2xl lg:text-3xl text-center font-bold">
            Klanten vertellen
          </h3>
          <Swiper slidesPerView={1} spaceBetween={50}>
            <SwiperSlide>
              <div className="rounded-lg w-full p-6 flex flex-col">
                <h3 className="text-1xl sm:text-1xl lg:text-2xl font-bold mb-4 flex flex-row items-center">
                  Slimmer zonder gas heeft ons geholpen de beste waarde te
                  krijgen
                </h3>
                <p className="mb-6 mt-auto">
                  "We waren op zoek naar een totaal oplossing voor onze woning.
                  Iedere partij kon een deel bijdragen. Het coordineren tussen
                  al deze afspraken was voor ons een nachtmerrie. Totdat we over
                  Slimmer zonder gas hoorden. Ze hebben ons enorm geholpen, en
                  wij hoefden alleen kenbaar te maken wat we precies wilden."
                </p>
                <i>- Rosalie uit Oosterhout</i>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg w-full p-6 flex flex-col">
                <h3 className="text-1xl sm:text-1xl lg:text-2xl text-center font-bold mb-4 flex flex-row items-center">
                  We wisten het niet zeker
                </h3>
                <p className="mb-6 mt-auto">
                  "Ik was niet zeker of dat we ook voor een wat kleinere
                  verbetering aan onze vloerverwarming terecht konden bij
                  Slimmer zonder Gas. Maar een klein telefoontje bewees toch al
                  gauw dat we meer dan 300 euro per jaar konden besparen."
                </p>
                <i>- Max uit Breda</i>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="rounded-lg w-full p-6 flex flex-col">
                <h3 className="text-1xl sm:text-1xl lg:text-2xl font-bold mb-4 flex flex-row items-center">
                  Totaaloplossing
                </h3>
                <p className="mb-6 mt-auto">
                  "Als Bedrijf A wilden we zo snel mogelijk van het gas af met
                  alle aanstormende veranderingen in de prijzen. Slimmer zonder
                  Gas heeft ons geholpen in het coordineren van alle partijen
                  zodat we zo snel mogelijk van het gas af konden."
                </p>
                <i>- Bedrijf A</i>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="w-full">
          <ContactForm />
        </div>
      </div>

      <div className="mb-24">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl text-center font-bold">
          De wereld verbeteren doe je niet alleen
        </h2>
        <p className="text-center">
          Je doet het samen met onze <b className="text-green-500">partners</b>
        </p>
      </div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-12">
        {Array.from([
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Partner 1',
          },
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Andere partner',
          },
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Partner 2',
          },
        ]).map((item, id) => {
          return <Tile image={item.image} name={item.name} key={id} />
        })}
      </div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-12">
        {Array.from([
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Partner 3',
          },
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Nog een partner',
          },
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'ACMA U B.V.',
          },
          {
            image: '../../../images/partners/led-solutions-holland.png',
            name: 'Partner 4',
          },
        ]).map((item, id) => {
          return <Tile image={item.image} name={item.name} key={id} />
        })}
      </div>

      <div className="flex flex-col items-center mb-24">
        <button className="hover:text-white hover:bg-transparent hover:border-purple-700 border bg-purple-600 transition duration-150 ease-in-out focus:outline-none mr-6  rounded text-white hover:text-purple-700 px-4 sm:px-8 py-1 sm:py-3 text-sm mb-2">
          Gelijk aan de slag
        </button>
      </div>
    </Layout>
  )
}

export default IndexPage
