import * as React from 'react'
import * as heroStyles from './hero.module.css'

function Hero() {
  return (
    <div className="sm:mb-8 mb-20 w-full">
      <div
        className={`mt-8 rounded-lg flex flex-col pt-12 p-6 sm:p-12 ${heroStyles.background} shadow-lg`}
      >
        <div className="sm:w-2/3 mb-5 sm:mb-10">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold leading-tight mb-4">
            slimmer zonder gas
          </h1>
          <p className={`text-white`}>
            Moeiteloos overstappen naar de modernste groene oplossingen.
          </p>
        </div>
        <div>
          <button className="hover:text-white hover:bg-transparent hover:border-white border bg-white transition duration-150 ease-in-out focus:outline-none mr-6  rounded text-purple-700 px-4 sm:px-8 py-1 sm:py-3 text-sm mb-2">
            Gelijk aan de slag
          </button>
          <button className="hover:bg-white hover:text-purple-600 hover:border-purple-600 bg-transparent transition duration-150 ease-in-out focus:outline-none hover:bg-purple-700-800 rounded border border-white text-white px-4 sm:px-8 py-1 sm:py-3 text-sm mb-2">
            Leer meer
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero
